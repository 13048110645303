<template>
    <section>
      
      <div class="columns is-marginless is-multiline">
        <div class="column is-12">    
            <div class="columns is-gapless is-marginless is-centered is-mobile">
              <div class="column is-narrow is-mobile" v-if="vacancy" :class="vacancy.dynamicPriceChris.totalPrice.toFixed(2) === vacancy.shiftRateTotalPrice.totalValue.toFixed(2) ? 'same' : 'different'">
                <div class="has-text-grey is-size-3 is-size-4-mobile has-text-weight-light wa-underlined" >shiftRates {{ this.shiftId }}</div>
              </div>
            </div>
        </div>
        
        <div v-if="vacancy" class="column is-2 is-offset-1">
          <div>
            shifts_id: {{ vacancy.wa_shifts_id  }}
            <br>
            vacancy_id: {{ vacancy.wa_shifts_vacancy_id  }}
            <br>
            code_id: {{ vacancy.wa_code_schedule_code_id  }}
            <br>
            code_schedule_id: {{ vacancy.wa_shifts_code_schedule_id  }}
          </div>
          <br>
          <div>
              {{vacancy.startDayName}} {{ vacancy.vDate }} {{ vacancy.tijd.split(` `).join(``) }} '{{ vacancy.wa_codes_code  }}' 
              <br>
              type:{{ vacancy.typeName  }}
              <br>
              isReserve: {{ vacancy.isReserve ? 'Ja' : 'Nee' }}
          </div>
          <br>
          <div>
              shifts_price: &euro;{{vacancy.shifts_price}}
              <br>
              codes_price: &euro;{{vacancy.codes_price}}
              <br>
              <strong>hourlyFinalPrice: </strong> &euro;{{vacancy.hourlyFinalPrice}}
              <br>
              <strong>totalValue:</strong> &euro;{{vacancy.totalValue.toFixed(2) }}
          </div>
          <br>
          <div v-if="vacancy.HolidayData">
              <strong>Holidays:</strong> {{ vacancy.HolidayData.length }}
              <div v-for="(holiday, hIndex) in vacancy.HolidayData" v-bind:key="`holiday_${hIndex}`" class="rateBlock"> 
                --> id:{{ holiday.id }} - {{ holiday.name }}<br>{{ DDMMYYYY_HHii(new Date(holiday.start_datetime)) }} till {{ DDMMYYYY_HHii(new Date(holiday.end_datetime)) }}<br>type:{{ holiday.type }}
                <br><strong>apply_holiday_rate:{{ holiday.apply_holiday_rate }}</strong>
              </div>
          </div>
          <br>
          <div v-if="vacancy.SchoolHolidayData">
              <strong>School Holidays:</strong> {{ vacancy.SchoolHolidayData.length }}
              <div v-for="(holiday, hIndex) in vacancy.SchoolHolidayData" v-bind:key="`holiday_${hIndex}`" class="rateBlock"> 
                --> id:{{ holiday.id }} - {{ holiday.name }}<br>{{ DDMMYYYY_HHii(new Date(holiday.start_datetime)) }} till {{ DDMMYYYY_HHii(new Date(holiday.end_datetime)) }}<br>type:{{ holiday.type }}
              </div>
          </div>
          <br>
        </div>
        
        <div v-if="vacancy" class="column is-8">
          
          <div>  
            <strong>Chris RateBlocks:</strong>
            <div v-for="(rateBlock, rIndex) in vacancy.dynamicPriceChris.finalBlocksArr" v-bind:key="`rateBlock_${rIndex}`" class="rateBlock"> 
              {{ DDMMYYYY_HHii(new Date(rateBlock.startTime)) }}-{{ HHii(new Date(rateBlock.endTime)) }} &euro;{{ rateBlock.rate }} prio:{{ rateBlock.prio }} ref:{{ rateBlock.ref }}/{{ rateBlock.note }} -> value: &euro;{{ ((rateBlock.endTime - rateBlock.startTime) / (3600*1000) * rateBlock.rate).toFixed(2) }}
            </div>
            <strong>dynamicPrice Chris duration:</strong> {{ vacancy.dynamicPriceChris.totalDuration }} uur
            <br>
            <strong>dynamicPrice Chris value:</strong> &euro;{{ vacancy.dynamicPriceChris.totalPrice.toFixed(2) }}
          </div>            
          
          <br><br>
          
          <div>              
            <strong>Yuri RateBlocks:</strong>
            <div v-for="(yuriRateBlock, rIndex) in vacancy.shiftRateList" v-bind:key="`rateBlock_${rIndex}`" class="rateBlock"> 
              {{ DDMMYYYY_HHii(new Date(yuriRateBlock.start_datetime)) }}-{{ HHii(new Date(yuriRateBlock.end_datetime)) }} &euro;{{ yuriRateBlock.hourly_rate }} prio:{{ yuriRateBlock.priority }} -> {{ yuriRateBlock.wa_company_rates_name}}
            </div>
            <strong>dynamicPrice Yuri value:</strong> &euro;{{ vacancy.shiftRateTotalPrice.totalValue.toFixed(2) }}
            <br>
          </div>
          
          <br><br>
          
          <div v-if="rateBlocksDetails !== null">              
            <strong>RateBlocks Details:</strong>
            <div class="strong">0 - Base Price: &euro;{{ rateBlocksDetails[`0 - Base Price`]}}</div>
            
            <div class="strong">10 - Company Rates:</div>
              <div v-for="(companyRates, crIndex) in rateBlocksDetails[`10 - Company Rates`]" v-bind:key="`companyRates_${crIndex}`" class="rateBlock"> 
                  id:{{ companyRates.wa_rates_group_id }}-{{ companyRates.GROUP_rates_name }}-{{ companyRates.wa_rates_name }} 
                  &euro;{{ companyRates.wa_rates_hourly_rate }}
                  {{ DDMMYYYY(new Date(companyRates.GROUP_rates_start_date))}} {{ DDMMYYYY(new Date(companyRates.GROUP_rates_end_date)) }} ->                  
                  {{ companyRates.wa_rates_holiday_only === 0 && companyRates.wa_rates_holiday_only === 0 && companyRates.wa_rates_monday === 1 ? `Ma` : ''}}
                  {{ companyRates.wa_rates_holiday_only === 0 && companyRates.wa_rates_holiday_only === 0 && companyRates.wa_rates_tuesday === 1 ? `Di` : ''}}
                  {{ companyRates.wa_rates_holiday_only === 0 && companyRates.wa_rates_holiday_only === 0 && companyRates.wa_rates_wednesday === 1 ? `Wo` : ''}}
                  {{ companyRates.wa_rates_holiday_only === 0 && companyRates.wa_rates_holiday_only === 0 && companyRates.wa_rates_thursday === 1 ? `Do` : ''}}
                  {{ companyRates.wa_rates_holiday_only === 0 && companyRates.wa_rates_holiday_only === 0 && companyRates.wa_rates_friday === 1 ? `Vr` : ''}}
                  {{ companyRates.wa_rates_holiday_only === 0 && companyRates.wa_rates_holiday_only === 0 && companyRates.wa_rates_saturday === 1 ? `Za` : ''}}
                  {{ companyRates.wa_rates_holiday_only === 0 && companyRates.wa_rates_holiday_only === 0 && companyRates.wa_rates_sunday === 1 ? `Zo` : ''}}
                  {{ companyRates.wa_rates_holiday_only === 1 ? `Holidays-only` : ''}}
                  {{ companyRates.wa_rates_school_holiday_only === 1 ? `School-Holidays-only` : ''}}
                  {{ `${companyRates.wa_rates_start_time.substr(0,5)}-${companyRates.wa_rates_end_time.substr(0,5)}` }}
              </div>
            <div class="strong">20 - Code Rates:</div>
              <div v-for="(codeRates, coIndex) in rateBlocksDetails[`20 - Code Rates`]" v-bind:key="`codeRates_${coIndex}`" class="rateBlock"> 
                  id:{{ codeRates.wa_rates_group_id }}-{{ codeRates.GROUP_rates_name }}-{{ codeRates.wa_rates_name }} 
                  &euro;{{ codeRates.wa_rates_hourly_rate }}
                  {{ DDMMYYYY(new Date(codeRates.GROUP_rates_start_date))}} {{ DDMMYYYY(new Date(codeRates.GROUP_rates_end_date)) }} ->
                  {{ codeRates.wa_rates_holiday_only === 0 && codeRates.wa_rates_holiday_only === 0 && codeRates.wa_rates_monday === 1 ? `Ma` : ''}}
                  {{ codeRates.wa_rates_holiday_only === 0 && codeRates.wa_rates_holiday_only === 0 && codeRates.wa_rates_tuesday === 1 ? `Di` : ''}}
                  {{ codeRates.wa_rates_holiday_only === 0 && codeRates.wa_rates_holiday_only === 0 && codeRates.wa_rates_wednesday === 1 ? `Wo` : ''}}
                  {{ codeRates.wa_rates_holiday_only === 0 && codeRates.wa_rates_holiday_only === 0 && codeRates.wa_rates_thursday === 1 ? `Do` : ''}}
                  {{ codeRates.wa_rates_holiday_only === 0 && codeRates.wa_rates_holiday_only === 0 && codeRates.wa_rates_friday === 1 ? `Vr` : ''}}
                  {{ codeRates.wa_rates_holiday_only === 0 && codeRates.wa_rates_holiday_only === 0 && codeRates.wa_rates_saturday === 1 ? `Za` : ''}}
                  {{ codeRates.wa_rates_holiday_only === 0 && codeRates.wa_rates_holiday_only === 0 && codeRates.wa_rates_sunday === 1 ? `Zo` : ''}}
                  {{ codeRates.wa_rates_holiday_only === 1 ? `Holidays-only` : ''}}
                  {{ codeRates.wa_rates_school_holiday_only === 1 ? `School-Holidays-only` : ''}}
                  {{ `${codeRates.wa_rates_start_time.substr(0,5)}-${codeRates.wa_rates_end_time.substr(0,5)}` }}
              </div>
            <div class="strong">30 - Code_Schedule Rates:</div>
              <div v-for="(codeScheduleRates, coSIndex) in rateBlocksDetails[`30 - Code_Schedule Rates`]" v-bind:key="`codeScheduleRates_${coSIndex}`" class="rateBlock"> 
                  id:{{ codeScheduleRates.wa_rates_group_id }}-{{ codeScheduleRates.GROUP_rates_name }}-{{ codeScheduleRates.wa_rates_name }} 
                  &euro;{{ codeScheduleRates.wa_rates_hourly_rate }}
                  {{ DDMMYYYY(new Date(codeScheduleRates.GROUP_rates_start_date))}} {{ DDMMYYYY(new Date(codeScheduleRates.GROUP_rates_end_date)) }} ->
                  {{ codeScheduleRates.wa_rates_holiday_only === 0 && codeScheduleRates.wa_rates_holiday_only === 0 && codeScheduleRates.wa_rates_monday === 1 ? `Ma` : ''}}
                  {{ codeScheduleRates.wa_rates_holiday_only === 0 && codeScheduleRates.wa_rates_holiday_only === 0 && codeScheduleRates.wa_rates_tuesday === 1 ? `Di` : ''}}
                  {{ codeScheduleRates.wa_rates_holiday_only === 0 && codeScheduleRates.wa_rates_holiday_only === 0 && codeScheduleRates.wa_rates_wednesday === 1 ? `Wo` : ''}}
                  {{ codeScheduleRates.wa_rates_holiday_only === 0 && codeScheduleRates.wa_rates_holiday_only === 0 && codeScheduleRates.wa_rates_thursday === 1 ? `Do` : ''}}
                  {{ codeScheduleRates.wa_rates_holiday_only === 0 && codeScheduleRates.wa_rates_holiday_only === 0 && codeScheduleRates.wa_rates_friday === 1 ? `Vr` : ''}}
                  {{ codeScheduleRates.wa_rates_holiday_only === 0 && codeScheduleRates.wa_rates_holiday_only === 0 && codeScheduleRates.wa_rates_saturday === 1 ? `Za` : ''}}
                  {{ codeScheduleRates.wa_rates_holiday_only === 0 && codeScheduleRates.wa_rates_holiday_only === 0 && codeScheduleRates.wa_rates_sunday === 1 ? `Zo` : ''}}
                  {{ codeScheduleRates.wa_rates_holiday_only === 1 ? `Holidays-only` : ''}}
                  {{ codeScheduleRates.wa_rates_school_holiday_only === 1 ? `School-Holidays-only` : ''}}
                  {{ `${codeScheduleRates.wa_rates_start_time.substr(0,5)}-${codeScheduleRates.wa_rates_end_time.substr(0,5)}` }}
              </div>
            <div class="strong">40 - Holiday Code Rates:</div>
              <div v-for="(holidayCodeRates, hcIndex) in rateBlocksDetails[`40 - Holiday Code Rates`]" v-bind:key="`holidayCodeRates_${hcIndex}`" class="rateBlock"> 
                  id:{{ holidayCodeRates.wa_rates_group_id }}-{{ holidayCodeRates.GROUP_rates_name }}-{{ holidayCodeRates.wa_rates_name }} 
                  &euro;{{ holidayCodeRates.wa_rates_hourly_rate }}
                  {{ DDMMYYYY(new Date(holidayCodeRates.GROUP_rates_start_date))}} {{ DDMMYYYY(new Date(holidayCodeRates.GROUP_rates_end_date)) }} ->
                  {{ holidayCodeRates.wa_rates_holiday_only === 0 && holidayCodeRates.wa_rates_holiday_only === 0 && holidayCodeRates.wa_rates_monday === 1 ? `Ma` : ''}}
                  {{ holidayCodeRates.wa_rates_holiday_only === 0 && holidayCodeRates.wa_rates_holiday_only === 0 && holidayCodeRates.wa_rates_tuesday === 1 ? `Di` : ''}}
                  {{ holidayCodeRates.wa_rates_holiday_only === 0 && holidayCodeRates.wa_rates_holiday_only === 0 && holidayCodeRates.wa_rates_wednesday === 1 ? `Wo` : ''}}
                  {{ holidayCodeRates.wa_rates_holiday_only === 0 && holidayCodeRates.wa_rates_holiday_only === 0 && holidayCodeRates.wa_rates_thursday === 1 ? `Do` : ''}}
                  {{ holidayCodeRates.wa_rates_holiday_only === 0 && holidayCodeRates.wa_rates_holiday_only === 0 && holidayCodeRates.wa_rates_friday === 1 ? `Vr` : ''}}
                  {{ holidayCodeRates.wa_rates_holiday_only === 0 && holidayCodeRates.wa_rates_holiday_only === 0 && holidayCodeRates.wa_rates_saturday === 1 ? `Za` : ''}}
                  {{ holidayCodeRates.wa_rates_holiday_only === 0 && holidayCodeRates.wa_rates_holiday_only === 0 && holidayCodeRates.wa_rates_sunday === 1 ? `Zo` : ''}}
                  {{ holidayCodeRates.wa_rates_holiday_only === 1 ? `Holidays-only` : ''}}
                  {{ holidayCodeRates.wa_rates_school_holiday_only === 1 ? `School-Holidays-only` : ''}}
                  {{ `${holidayCodeRates.wa_rates_start_time.substr(0,5)}-${holidayCodeRates.wa_rates_end_time.substr(0,5)}` }}
              </div>
            <div class="strong">50 - Shift Custom Rate:</div>
              <div v-for="(customPriceRates, cpIndex) in rateBlocksDetails[`50 - Shift Custom Rate`]" v-bind:key="`customRates_${cpIndex}`" class="rateBlock"> 
                  id:{{ customPriceRates.wa_rates_group_id }}-{{ customPriceRates.GROUP_rates_name }}-{{ customPriceRates.wa_rates_name }} 
                  &euro;{{ customPriceRates.wa_rates_hourly_rate }}
                  {{ DDMMYYYY(new Date(customPriceRates.GROUP_rates_start_date))}} {{ DDMMYYYY(new Date(customPriceRates.GROUP_rates_end_date)) }} ->
                  {{ customPriceRates.wa_rates_holiday_only === 0 && customPriceRates.wa_rates_holiday_only === 0 && customPriceRates.wa_rates_monday === 1 ? `Ma` : ''}}
                  {{ customPriceRates.wa_rates_holiday_only === 0 && customPriceRates.wa_rates_holiday_only === 0 && customPriceRates.wa_rates_tuesday === 1 ? `Di` : ''}}
                  {{ customPriceRates.wa_rates_holiday_only === 0 && customPriceRates.wa_rates_holiday_only === 0 && customPriceRates.wa_rates_wednesday === 1 ? `Wo` : ''}}
                  {{ customPriceRates.wa_rates_holiday_only === 0 && customPriceRates.wa_rates_holiday_only === 0 && customPriceRates.wa_rates_thursday === 1 ? `Do` : ''}}
                  {{ customPriceRates.wa_rates_holiday_only === 0 && customPriceRates.wa_rates_holiday_only === 0 && customPriceRates.wa_rates_friday === 1 ? `Vr` : ''}}
                  {{ customPriceRates.wa_rates_holiday_only === 0 && customPriceRates.wa_rates_holiday_only === 0 && customPriceRates.wa_rates_saturday === 1 ? `Za` : ''}}
                  {{ customPriceRates.wa_rates_holiday_only === 0 && customPriceRates.wa_rates_holiday_only === 0 && customPriceRates.wa_rates_sunday === 1 ? `Zo` : ''}}
                  {{ customPriceRates.wa_rates_holiday_only === 1 ? `Holidays-only` : ''}}
                  {{ customPriceRates.wa_rates_school_holiday_only === 1 ? `School-Holidays-only` : ''}}
                  {{ `${customPriceRates.wa_rates_start_time.substr(0,5)}-${customPriceRates.wa_rates_end_time.substr(0,5)}` }}
              </div>
            
            <br>
          </div>
          
        </div>
      </div>
      
      
       <div class="columns is-marginless is-multiline">
          <div v-if="errorLog" class="column is-11 is-offset-1">    
            errorLog:
            <div label="errorLog:" >
              <label class="label">errorLog</label>
              <b-input maxlength="20000000" type="textarea" disabled custom-class="inputheight3" :value="errorLog.join(`\r\n`)"> 
              </b-input>
            </div>
          </div>
       </div>
       
       <div v-if="log" class="column is-11 is-offset-1">
          <div label="log:" >
            <label class="label">log:</label>
            <b-input maxlength="20000000" type="textarea" disabled custom-class="inputheight3" :value="log.join(`\r\n`)"> 
            </b-input>
          </div>
      </div>
      
      
        
      
      <b-notification :closable="false">
        <b-loading :is-full-page="true" :active.sync="pageLoading"></b-loading>
      </b-notification>
    </section>
</template>

<script>

import axios_api from '@/plugins/axios_api';
import { DDMMYYYY, DDMMYYYY_HHii, HHii }  from '@/helpers/dates';
// import { mapState } from 'vuex';

/* eslint-disable */
import { WEB_ROOT, API_ROOT } from '@/config/app.js';

    export default {
      data() {
        return {
          companyId           : null,
          shiftId             : null,
          
          companyData         : null,
          dbRateBlocks        : null,
          rateBlockFull       : null,
          log                 : null,
          errorLog            : null,
          holidayData         : null,
          SchoolHolidayData   : null,
          vacancy             : null,
          rateBlocksDetails   : null,
          
          // tableIsLoading      : false,
          WEB_ROOT: WEB_ROOT,
          API_ROOT: API_ROOT,
          
          pageLoading         : false,
          pageLoadingTimeout  : null,
        }
      },
      async mounted() {
        this.companyId  = this.$route.params.companyId;
        this.shiftId    = this.$route.params.shiftId;
        
        this.ShowPageLoading(15000)
        this.getData()
      },
      methods: {
        DDMMYYYY, DDMMYYYY_HHii, HHii,
        async getData() {
          let response = await axios_api.get(`${API_ROOT}/schedule/company/${this.companyId}/rates/${this.shiftId}`, {});
          if (response.data.result !== 1) {
            this.$buefy.dialog.alert({ title: 'Error', message: 'unable to get company data', type: 'is-danger' })
            this.HidePageLoading()
            return;
          }
          console.info(`response:`, response)
          this.companyData    = response.data.payload.companyData
          this.dbRateBlocks   = response.data.payload.dbRateBlocks
          this.rateBlockFull  = response.data.payload.rateBlockFull
          this.log            = response.data.payload.log
          this.errorLog       = response.data.payload.errorLog
          this.holidayData    = response.data.payload.holidayData
          this.SchoolHolidayData  = response.data.payload.SchoolHolidayData
          this.vacancy            = response.data.payload.vacancy
          this.rateBlocksDetails  = response.data.payload.rateBlocksDetails
          console.info(`this.rateBlocksDetails:`, this.rateBlocksDetails)
          
          this.HidePageLoading()
        },
        
        
        
        
        
        
        
        async ShowPageLoading(maxDuration = 30000){
          this.pageLoading = true;
          
          if (this.pageLoadingTimeout !== null) {
            clearTimeout(this.pageLoadingTimeout);
          }
          
          this.pageLoadingTimeout = setTimeout(() => {
            this.HidePageLoading();
          }, maxDuration);
        },
        
        async HidePageLoading() {
          this.pageLoading = false;
          if (this.pageLoadingTimeout !== null) {
            clearTimeout(this.pageLoadingTimeout);
          }
        },
      }
    }
</script>

<style>
  .highlight{
    color: #0000cc;
    text-decoration: underline;
  }
  
  /* .isAvtive{
    color: green
  } */
  .hasErrors{
    color: red
  }
  
  .same{
    background-color: lightgreen;
  }
  .different{
    background-color: lightcoral;
  }
  .rateBlock{
    margin-left: 2%;
    margin-top: 5px;
    margin-bottom: 5px;
    /* font-size: 20px; */
  }
  .inputheight3{
    font-size: 14px;
    height: 600px;
  }
  
  
</style>